@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  @font-face {
    font-family: 'Comfortaa';
    src: url('./fonts/Comfortaa/static/Comfortaa-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Comfortaa';
    src: url('./fonts/Comfortaa/static/Comfortaa-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Comfortaa';
    src: url('./fonts/Comfortaa/static/Comfortaa-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Comfortaa';
    src: url('./fonts/Comfortaa/static/Comfortaa-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Comfortaa';
    src: url('./fonts/Comfortaa/static/Comfortaa-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  html {
    scroll-behavior: smooth;
  }

  body {
    font-weight: 400;
    font-family: 'Comfortaa', sans-serif;
    font-size: 16px;
   
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
  } 


  .shadow-custom {
    box-shadow: rgba(10, 15, 20, 0.4) 0px 7px 20px 0px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  }

}

.hover-items:hover h3  {
  color: #EEBE57;
}
.hover-items:hover img, .hover-items:focus img, .hover-items:active img  {
  filter: brightness(0) saturate(100%) invert(85%) sepia(33%) saturate(498%) hue-rotate(344deg) brightness(96%) contrast(102%);

}

.img-avcent  {
  filter: brightness(0) saturate(100%) invert(85%) sepia(33%) saturate(498%) hue-rotate(344deg) brightness(96%) contrast(102%);
}
.swiper-button-next,
.swiper-button-prev {
  color: black !important; 
  background-color: white; 
  padding: 24px;
  text-align: center;
  border-radius: 50%; 
  transition: color 300ms;
}
.swiper-button-next:hover,
.swiper-button-prev:hover  {
 color: #EEBE57 !important;

}
.swiper-button-next{
  right:  24px !important;
}
.swiper-button-prev{
  left:  24px !important;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after, 
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  font-size: 20px !important;
  font-weight: 700;
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    padding: 8px !important;
    height: 30px !important;
  }

  .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after, 
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    font-size: 16px !important; 

  }
}
body.overflow-hidden {
  overflow: hidden;
  position: relative; 
  height: 100%;       
  width: 100%;      
}
.own-input  input:-webkit-autofill,
.own-input  input:-webkit-autofill:hover,
.own-input  input:-webkit-autofill:focus,
.own-input  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
    color: white !important;
}